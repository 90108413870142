.App {
  margin-top: 50px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: monospace;
  font-size: large;
}
label {
    color: white;
}



.WalletConnect {
    padding-left: 1vh;
        margin-top: 1vh;
        align-self: flex-end;
        
}

.topTitle {
    display: flex;
    justify-content: space-between;
}


h1 {
    color: #4CAF50;
    
}
h2 {
    color: #4CAF50;
    font-size: 1.7em;
}
h3 {
    color: #4CAF50;
    font-size: 1.5em;
}

input {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 2px solid #4CAF50;
    border-radius: 10px;
    font-family: monospace;
    font-size: large;
    background-color: #1e1e1e;
    color:#cdcdcd;
    transition: all 0.3s ease;
    box-shadow: 0 0px 4px rgba(76, 175, 79, 0.655);
    /* padding-left: 200px; */
}


input:focus {
    outline: none;
    border-color: #45a049;
    box-shadow: 0 0 10px rgba(0, 255, 8, 0.955);
}
input:hover {
    outline: none;
    border-color: #45a049;
    box-shadow: 0 0 40px rgba(76, 175, 79, 0.855);
    transform: scale(1.01)

}

input::placeholder {
    color: #999;
    /* padding-left: 10px; */
}

input:disabled {
    background-color: #f5f5f5;
    border-color: #ddd;
    cursor: not-allowed;
}
input[value="text"]{
    padding-left: 10px;
}






input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.TransferForm {
    
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(76, 175, 80, 0.2);
    padding: 2rem;
    /* max-width: 600px; */
    margin: 2rem auto;
    
}
.TransferForm input {
    margin-left: 20px;
}
.FormContent .formItem{
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    align-items: center;
    

}


.TransferForm .inputField {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    flex-direction: column;
    
    
}
.TransferForm .FormContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TransferForm label {
    color: #ffffff;
    font-size: 1.1em;
    margin-bottom: 0.5rem;
    display: block;
}

.TransferForm button {
    /* width: 5em; */
    padding: 15px;
    padding-left: 40px;
    padding-right:40px;
    font-size: 1.1em;
    font-weight: bold;
    border-radius: 2em;
    text-transform: uppercase;
    transition: all 0.3s ease;
    margin-left: auto;
    display: block;
    box-shadow: 0 0 20px #4caf4fb7;
    margin-bottom: .5em;
    margin-left: 1em;



    
}
button {
    font-weight: bold;
    border-radius: 2em;
    text-transform: uppercase;
    transition: all 0.3s ease;
    box-shadow: 0 0 20px #4caf4fb7;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.TransferForm button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
button:not(:disabled):hover {
    box-shadow: 0 0 40px #4caf4fd0;
    transform:scale(1.05)
}

.TransferForm button:not(:disabled):hover {
    box-shadow: 0 0 40px #4caf4fd0;
    transform:scale(1.05)
}

.TransferForm p {
    text-align: center;
    color: #666;
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
}

.SwapTokens {
    display: block;
    /* width: 90%;
    margin: 0 auto;
    padding: 20px; */
    
}

.SwapTokens h2 {
    /* color: #ffffff; */
    /* text-align: center; */
    margin-bottom: 30px;
    
}

.search-container {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    
}

.search-container label {
    color: #ffffff;
    display: block;
    margin-bottom: 10px;
    font-size: 1.1em;
    margin-right: auto;
    
}
.search-container input {
    width: 70%;
}

.tokens-list {
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(76, 175, 80, 0.2);

    padding: 20px;
    margin-bottom: 30px;
    
    
}

.tokens-list h3 {
    color: #ffffff;
    margin-bottom: 20px;
}

.token-item {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 15px;
    list-style: none;
}

.token-info {
    display: flex;
    align-items: center;
    gap: 20px;
}

.token-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.token-details {
    flex-grow: 1;
}

.token-details strong {
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
}

.token-address {
    color: #aaaaaa;
    font-size: 0.9em;
    margin: 5px 0;
}

.token-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.token-buttons button {
    padding: 8px 15px;
    font-size: 0.9em;
}

.swap-details {
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(76, 175, 80, 0.2);
    padding: 20px;
}

.swap-details h3 {
    color: #ffffff;
    margin-bottom: 20px;
}

.token-addresses {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.address-field {
    flex: 1;
    margin: 0 10px;
}

.address-field label {
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
}

.address-field p {
    background: rgba(255, 255, 255, 0.05);
    padding: 10px;
    border-radius: 8px;
    word-break: break-all;
}
.AmountAndButton{
    display: flex;
    justify-content: space-between;
    

}
.amount-input {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    align-items: center;
}

.amount-input label {
    color: #ffffff;
    display: block;
    margin-bottom: 10px;
    margin-right: 2em;
}

.quote-section {
    text-align: center;
}

.quote-button, .swap-button {
    margin: 10px;
    padding: 12px 30px;
    font-size: 1.1em;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.quote-button:hover, .swap-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

.quote-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.quote-details {
    margin-top: 20px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    
}

.quote-details h4 {
    color: #ffffff;
    margin-bottom: 10px;
    
}
.quote-details .estout {
    display: block;
    justify-content: center;
    font-family: monospace;
    font-size: large;
    background-color: #1e1e1e;
    border-radius: .5em;
    padding-left: 2px;
}
.quote-details .estout p {
    display: flex;
    padding:10px;
    color:#cdcdcd
}


.status-message {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    display: block;
    justify-content: center;
    font-family: monospace;
    font-size: large;
    background-color: #1e1e1e;
    color:#cdcdcd;
    border-radius: .5em;
    padding-left: 10px;

    
    
}
.transactionStatus{
    display: flex;
    flex-wrap: wrap;
    hyphens: auto;
    word-wrap: break-word;
    
}

.monostyle {
    font-family: monospace;
    font-size: 0.8em;
    display: flex;
    flex-wrap: wrap;

}
::marker{
    color: white;
}

button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: monospace;
    

}

button:hover {
    background-color: #45a049;
}




label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}



button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

p {
    margin-top: 20px;
    font-size: 1em;
    color: #ffffff;
}
select, input {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}
